import React from "react"
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Estimate from "./estimate"
import AboutPrevious from "./aboutPrevious"
import AboutLast from "./aboutLast"

const About = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      allContentfulAbout (sort: {order: ASC, fields: createdAt}) {
        edges {
          node {
            aboutTitle {
              aboutTitle
            }
            aboutContent {
              aboutContent
            }
            aboutSubtitle
            aboutSubcontent {
              aboutSubcontent
            }
            aboutImage {
              fluid  (maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
          }
        }
      }
      contentfulStartEstimate {
        startEstimateTitle
        startEstimateDescription {
          startEstimateDescription
        }
        startEstimateImage {
          fixed  (width: 400, resizingBehavior: SCALE) {
            ...GatsbyContentfulFixed_withWebp
          }
          title
        }
      }
    }
  `);

  const lastAboutItem = data.allContentfulAbout.edges.length-1;

  return (
    <>
      <AboutPrevious 
        data={data.allContentfulAbout.edges}
      />
      <Estimate 
        data={data.contentfulStartEstimate}
      />
      <AboutLast 
        data={data.allContentfulAbout.edges[lastAboutItem]}
        count={lastAboutItem}
      />
    </>
  )
}

export default About
