import React from 'react';
import Img from "gatsby-image"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import UnderlinedTitle from "../../underlinedTitle"

const useStyles = makeStyles((theme) => ({
  
  aboutSubtitle: {
    marginTop: 20,
    color: theme.palette.green[900]
  },
  aboutSubcontent: {
    marginTop: 20
  },
}));

const AboutLast = ({
  data,
  count
}) => {
  const classes = useStyles();

  return (
    <Container>
      {
        data.node &&
          (
            <Box
              key={data.node.aboutTitle.aboutTitle}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              py={5}
            >
              <UnderlinedTitle
                variant="h2"
                title={data.node.aboutTitle.aboutTitle}
                uppercase={true}
                align="center"
                color="green"
              />
              <Box
                py={5}
              >
                <Grid
                  container
                  direction={count % 2 ? "row-reverse" : "row"}
                  justify="space-between"
                  className="page"
                  spacing={5}
                >
                  <Grid
                    item
                    md={6}
                    xl={6}
                    xs={12}
                  >
                    <Img
                      fluid={data.node.aboutImage.fluid}
                      alt={data.node.aboutImage.title}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xl={6}
                    xs={12}
                  >
                    {
                      data.node.aboutContent && (
                        <Typography
                          variant="body1"
                        >
                          {data.node.aboutContent.aboutContent}
                        </Typography>
                      )
                    }
                    {
                      data.node.aboutSubtitle && (
                        <Typography
                          variant="body1"
                          className={classes.aboutSubtitle}
                        >
                          {data.node.aboutSubtitle}
                        </Typography>
                      )
                    }
                    {
                      data.node.aboutSubcontent && (
                        <Typography
                          variant="body1"
                          className={classes.aboutSubcontent}
                        >
                          {data.node.aboutSubcontent.aboutSubcontent}
                        </Typography>
                      )
                    }
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )
      }
    </Container>
  )
}

export default AboutLast