import React from 'react';
import Img from "gatsby-image"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'linear-gradient(#3e95af, #87c9e0)',
    position: "absolute",
    width: '100%',
    boxShadow: '0 56px 23px 3px #d5d6d7',

    [theme.breakpoints.down('sm')]: {
      position: "relative"
    },
  },
  aboutSubtitle: {
    marginTop: 20,
    color: theme.palette.green[900]
  },
  aboutSubcontent: {
    marginTop: 20
  },
  paintingMan: {
    position: 'relative',
    marginBottom: -6,

    [theme.breakpoints.down('sm')]: {
      display: "none"
    },

  },
  container: {
    // position: "relative"
  },
  description: {
    color: theme.palette.default.main
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.default.main
  },
  btnFreeEstimate: {
    color: theme.palette.default.main,
    border: `3px solid ${theme.palette.default.main}`,
    textTransform: "uppercase",
    backgroundImage: 'linear-gradient(#74bbd3, #3e95af)',
    height: 60,
    width: 185,
  },
  startEstimationContainer: {
    zIndex: 1
  }
}));

const Estimate = ({
  data
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="flex-end"
    >
      <div
        className={classes.root}
      >
        <Container
          className={classes.container}
        >
          <Box
            py={5}
          >
            <Grid
              container
            >
              <Grid
                item
                md={6}
                className={classes.startEstimationContainer}
              >
                {
                  data.startEstimateDescription && (
                    <Typography
                      variant="h6"
                      align="center"
                      component="p"
                      className={classes.description}
                    >
                      {data.startEstimateDescription.startEstimateDescription}
                    </Typography>
                  )
                }
                {
                  data.startEstimateTitle && (
                    <Box
                      py={5}
                    >
                      <Typography
                        variant="h2"
                        component="p"
                        align="center"
                        className={classes.title}
                      >
                        {data.startEstimateTitle}
                      </Typography>
                    </Box>
                  )
                }
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    className={classes.btnFreeEstimate}
                  >
                    Free Estimate
                  </Button>
                </Box>
                
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <Grid
        container
        className={classes.paintingMan}
      > 
        <Grid
          item
          md={7}
        />
        <Grid
          item
          md={5}
        >
          <Img
            fixed={data.startEstimateImage.fixed}
            alt={data.startEstimateImage.title}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Estimate